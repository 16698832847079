.CCPGrid {
  height: 85vh;
}
.CustomerInfoGrid {
  height: 100vh;
}

.LowerGrid {
  background-color: rgb(214, 213, 213);
  height: 30vh;
}
