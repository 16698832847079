.LeadInfo {
  height: 85vh;
}

.SubmitButton {
  width: 75px;
  position: absolute;
  left: calc(100% - 75px);
  bottom: 0px;
  margin-top: 50px;
}

.CrmRecordUrl {
  position: absolute;
  right: 30px;
}

.SpinnerContainer {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
