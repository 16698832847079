* {
  box-sizing: border-box;
  font-family: 'AmazonEmber';
}

.Invisible {
  display: none;
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
