.Footer {
  background-color: #232f3e;
  color: white;
  text-align: right;
  padding: 0.1%;
  padding-right: 1rem;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 30px;
}

.RemoveFooter {
  display: none;
}

.Contents {
  display: inline-block;
  margin: 0.5rem 1rem 0.3rem 1rem;
  font-size: 0.7rem; /*fallback in case media query doesn't work*/
  height: 17.5px;
}

.Link {
  text-decoration: none;
  color: white;
}

.Close {
  position: absolute;
  right: 10px;
  font-size: 20px;
}

.Close:hover {
  cursor: pointer;
}

.FeedbackIcon {
  transform: scale(0.7);
  margin-left: 10px;
}

.FeedbackText {
  display: inline-block;
  margin-top: 0.5rem;
}

.FeedbackSection {
  width: 100px;
  position: absolute;
  font-size: 0.7rem;
  text-align: left;
}

.FeedbackSection:hover {
  cursor: pointer;
}

.FooterContentSection {
  display: inline-block;
  width: 700px;
}
